import appService from '@/services/app.js'

export default {
  namespaced: true,
  state: {
    initialLoader: false,
    shallContentShowOverlay: false,
    user: {
      userId: '6226e8d6af832f17f6406cdb',
      mockStatus: '',
      userName: 'Usman',
      userStatus: 'superadmin',
      companyName: 'Alpha Investment Company',
      companyId: '598820ce75e2ab007f193aed',
      companyLogo: '',
    },
    companies: [],
    selectedCompany: null,
  },
  getters: {
    loading: state => state.initialLoader,
    loggedInUser: state => state.user,
    companies: state => state.companies,
    selectedCompany: state => state.selectedCompany,
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.initialLoader = payload
    },
    TOGGLE_CONTENT_OVERLAY(state, value) {
      state.shallContentShowOverlay = value !== undefined ? value : !state.shallContentShowOverlay
    },
    SET_COMPANIES(state, payload) {
      state.companies = payload
    },
    SET_SELECTED_COMPANY(state, payload) {
      state.selectedCompany = payload
    },
  },
  actions: {
    async login(storeContext, payload) {
      const response = await appService.login(payload)
      if (response.success) {
        localStorage.setItem('accessToken', response.token)

        return response
      } else {
        return response
      }
    },
    async fetchCompanies({ commit }) {
      commit('SET_LOADING', true)
      commit('SET_COMPANIES', [])
      const data = await appService.fetchCompanies()
      commit('SET_COMPANIES', data)
      commit('SET_SELECTED_COMPANY', data[0].id)
      commit('SET_LOADING', false)
    },
  },
}
