import paDealingService from '@/services/pa-dealing'
export default {
  namespaced: true,
  state: {
    // * staff module
    staff: [],
    accounts: [],
  },
  getters: {
    companyStaff: state => state.staff,
    companyStaffAccounts: state => state.accounts,
  },
  mutations: {
    SET_STAFF(state, payload) {
      state.staff = payload
    },
    SET_ACCOUNTS(state, payload) {
      state.accounts = payload
    },
    REMOVE_STAFF(state, id) {
      const data = state.staff?.filter(staff => staff.id !== id)
      state.staff = data
    },
    REMOVE_ACCOUNT(state, id) {
      const data = state.accounts?.filter(accounts => accounts.id !== id)
      state.accounts = data
    },
  },
  actions: {
    // * staff api
    async fetchCompanyStaff({ commit }, companyID) {
      commit('SET_STAFF', [])
      const data = await paDealingService.fetchCompanyStaff(companyID)
      commit('SET_STAFF', data)
    },

    async updateApprover(storeContext, payload) {
      const { success } = await paDealingService.updateApprover(payload)
      return success
    },
    async removeCompanyStaff(storeContext, staffID) {
      const response = await paDealingService.removeCompanyStaff(staffID)
      return response
    },

    // * account number api
    async fetchAccounts({ commit }, companyID) {
      commit('SET_ACCOUNTS', [])
      const data = await paDealingService.fetchAccounts(companyID)
      commit('SET_ACCOUNTS', data)
    },
    async createAccountNumber(storeContext, payload) {
      const response = await paDealingService.createAccountNumber(payload)
      return response
    },
    async updateAccountNumber(storeContext, payload) {
      const response = await paDealingService.updateAccountNumber(payload)
      return response
    },
    async removeAccountNumber(storeContext, id) {
      const response = await paDealingService.removeAccountNumber(id)
      return response
    },
    async accountsUploadFile(storeContext, payload) {
      const response = await paDealingService.accountsUploadFile(payload)
      return response
    },
    async accountsRemoveFile(storeContext, payload) {
      const response = await paDealingService.accountsRemoveFile(payload)
      return response
    },
  },
}
