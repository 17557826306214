import transformers from '@/transformers'
import axios from './axios-config'
const { createError, createSuccessResponse, createSuccessMsg } = transformers

export default {
  async fetchCompanyStaff(companyID) {
    try {
      const { data } = await axios.get(`/users/list/${companyID}?trim=true`)
      return transformers.mapCompanyStaff(data)
    } catch (error) {}
  },
  async fetchAccounts(companyID) {
    try {
      const { data } = await axios.get(
        `/account-numbers/companies/${companyID}?populateUserId=true`,
      )
      return transformers.mapAccounts(data)
    } catch (error) {}
  },
  async removeCompanyStaff(id) {
    try {
      const { status } = await axios.delete(`/removeStaffUser/${id}`)
      return status === 200
        ? createSuccessMsg('Staff removed Successfully')
        : createError('Something went wrong!')
    } catch (error) {
      return createError('Something went wrong!')
    }
  },
  async updateApprover(payload) {
    try {
      const response = await axios.put(`saveApprover/${payload.userID}/${payload.companyID}`, {
        LoggedUserId: payload.LoggedUserId,
        editUser: payload.editUser,
      })
      return { success: response.status === 200 }
    } catch (error) {
      return { success: false }
    }
  },
  async createAccountNumber(payload) {
    try {
      const { status } = await axios.post(`/account-numbers`, payload)

      return status === 200
        ? createSuccessMsg('Account number added Successfully')
        : createError('Something went wrong!')
    } catch (error) {
      return createError('Something went wrong!')
    }
  },
  async updateAccountNumber(payload) {
    try {
      const { status } = await axios.put(`/editAccSave/${payload.userID}`, payload.content)

      return status === 200
        ? createSuccessMsg('Account updated')
        : createError('Something went wrong!')
    } catch (error) {
      return createError('Something went wrong!')
    }
  },
  async removeAccountNumber(id) {
    try {
      const { status } = await axios.delete(`/account-numbers/${id}`)

      return status === 200
        ? createSuccessMsg('Account number removed Successfully')
        : createError('Something went wrong!')
    } catch (error) {
      return createError('Something went wrong!')
    }
  },
  async accountsUploadFile(payload) {
    try {
      const { data } = await axios.post(
        `upload-file/accountnumbers/${payload.companyID}?Tool=PA Dealing&Section=Account Numbers`,
        payload.file,
      )
      return createSuccessResponse(data)
    } catch (error) {
      return createError('Something went wrong!')
    }
  },
  async accountsRemoveFile(payload) {
    try {
      const { status } = await axios.delete(`delete-file/accountnumbers`, {
        data: payload,
      })
      return status === 200
        ? createSuccessMsg('File Removed Successfully')
        : createError(`${status}  Something went wrong!`)
    } catch (error) {
      return createError('Something went wrong!')
    }
  },
}
