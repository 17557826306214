import {
  mdiAccountGroupOutline,
  mdiCancel,
  mdiChartTimelineVariant,
  mdiChatQuestionOutline,
  mdiCheckDecagramOutline,
  mdiHandshakeOutline,
  mdiNotebookOutline,
  mdiScriptTextOutline,
  mdiTextBoxMultipleOutline,
  mdiTransitConnectionVariant,
} from '@mdi/js'

export default [
  {
    title: 'PA Dealing',
    icon: mdiHandshakeOutline,
    children: [
      {
        title: 'Staff',
        icon: mdiAccountGroupOutline,
        to: { name: 'pa-dealing-staff-list' },
      },
      {
        title: 'Policies',
        icon: mdiScriptTextOutline,
        to: { name: 'pa-dealing-policies' },
      },
      {
        title: 'Restricted List',
        icon: mdiCancel,
        to: { name: 'pa-dealing-restricted-list' },
      },
      {
        title: 'Statements',
        icon: mdiNotebookOutline,
        to: { name: 'pa-dealing-statements' },
      },
      {
        title: 'Approvals',
        icon: mdiCheckDecagramOutline,
        to: { name: 'pa-dealing-approvals' },
      },
      {
        title: 'Transactions',
        icon: mdiTransitConnectionVariant,
        children: [
          {
            title: 'Staff',
            to: { name: 'pa-dealing-transactions-staff' },
          },
          {
            title: 'Portfolio',
            to: { name: 'pa-dealing-transactions-portfolio' },
          },
          {
            title: 'Analysis',
            to: { name: 'pa-dealing-transactions-analysis' },
          },
        ],
      },
      {
        title: 'Transactions Rules',
        icon: mdiTextBoxMultipleOutline,
        to: { name: 'pa-dealing-transaction-rules' },
      },
      {
        title: 'PA Requests',
        icon: mdiChatQuestionOutline,
        to: { name: 'pa-dealing-pa-requests' },
      },
      {
        title: 'PA Reports',
        icon: mdiChartTimelineVariant,
        to: { name: 'pa-dealing-pa-reports' },
      },
    ],
  },
]
