import Transformers from '@/transformers'
import axios from './axios-config'

export default {
  // * auth
  async login(payload) {
    try {
      const { data } = await axios.post('/api/v1/user/login', payload)
      return {
        success: true,
        token: data.token,
        tokenType: data.token_type,
      }
    } catch (error) {
      if (error.response.data.message) {
        return {
          success: false,
          message: error.response.data.message,
        }
      }
    }
  },

  async fetchCompanies() {
    try {
      const { data } = await axios.get('/padealing/main')
      return Transformers.mapCompanies(data.companies)
    } catch (error) {
      console.error('fetchCompanies:', error)
    }
  },
}
