import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import paDealing from './modules/pa-dealing'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    appConfig: appConfigStoreModule,
    app,
    paDealing,
  },
})
