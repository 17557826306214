<template>
  <v-card class="mb-4">
    <v-card-title>
      <span>
        <slot></slot>
      </span>
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  name: 'ModuleHeading',
}
</script>

<style lang="scss" scoped></style>
