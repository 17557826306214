import { mdiChartBar, mdiFileChartOutline } from '@mdi/js'

export default [
  {
    title: 'CRS/FATCA',
    icon: mdiChartBar,
    children: [
      {
        title: 'CRS',
        icon: mdiFileChartOutline,
        to: { name: 'crs' },
      },
      {
        title: 'FATCA',
        icon: mdiFileChartOutline,
        to: { name: 'fatca' },
      },
    ],
  },
]
