import axios from 'axios'

axios.defaults.baseURL = 'https://staging.compliancedepartment.online'

axios.interceptors.response.use(
  function (response) {
    response.body = response.data
    return response
  },
  function (error) {
    if (error.response.status === 401) {
      console.log('Unauthorized Request')
      localStorage.removeItem('accessToken')
      window.location.replace(`${window.location.origin}/`)
    }
    if (error.response.status === 404 && error.response.data === 'Invalid token, user not found') {
      localStorage.removeItem('accessToken')
      window.location.replace(`${window.location.origin}/`)
    }
    return Promise.reject(error)
  },
)

axios.interceptors.request.use(
  config => {
    const accessToken = localStorage.getItem('accessToken')
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    return config
  },
  error => Promise.reject(error),
)

export default axios
