<template>
  <v-snackbar
    v-model="visible"
    :color="type"
    :bottom="bottom"
    :centered="centered"
    :top="top"
    :left="left"
    :content-class="contentClass"
    transition="scroll-y-reverse-transition"
    height="4rem"
    text
    outlined
  >
    {{ message }}
    <template v-slot:action="{ attrs }">
      <ac-icon-btn
        btn-class="me-2"
        :icon="icons.mdiClose"
        :color="type"
        v-bind="attrs"
        no-tooltip
        @click="onClose"
      >
        Close
      </ac-icon-btn>
    </template>
  </v-snackbar>
</template>

<script>
import AcIconBtn from '@/components/AcIconBtn.vue'
import { mdiClose } from '@mdi/js'

export default {
  components: {
    AcIconBtn,
  },
  data: () => ({
    icons: {
      mdiClose,
    },
    visible: false,
    type: 'primary',
    message: null,
    bottom: false,
    centered: false,
    top: false,
    left: false,
    contentClass: null,
  }),
  methods: {
    showAlert({ type, message, position = '', contentClass }) {
      this.visible = true
      this.type = type
      this.message = message
      this.bottom = position.includes('bottom')
      this.top = position.includes('top')
      this.centered = position.includes('centered')
      this.left = position.includes('left')
      this.contentClass = contentClass
    },
    onClose() {
      this.visible = false
      this.message = null
    },
  },
}
</script>
<style lang="scss" scoped></style>
