import {
  mdiClipboardListOutline,
  mdiClipboardTextOutline,
  mdiClipboardTextSearchOutline,
} from '@mdi/js'

export default [
  {
    title: 'Compliance Report',
    icon: mdiClipboardListOutline,
    children: [
      {
        title: 'Report',
        icon: mdiClipboardTextOutline,
        to: { name: 'compliance-report' },
      },
      {
        title: 'Report Tracker',
        icon: mdiClipboardTextSearchOutline,
        to: { name: 'compliance-report-tracker' },
      },
    ],
  },
]
