const aml = [
  {
    path: '/aml-kyc',
    name: 'aml-kyc',
    component: () => import('@/views/aml-kyc/Index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/resetpassowrd',
    name: 'reset-password',
    component: () => import('@/views/resetpassword.vue'),
    meta: {
      layout: 'content',
    },
  },
]

//Statements

export default aml
