// axios
import axios from 'axios'
import Vue from 'vue'

const axiosIns = axios.create({
  // baseURL: 'https://some-domain.com/api/',
})

axiosIns.interceptors.request.use(
  config => {
    const accessToken = localStorage.getItem('accessToken')
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    return config
  },
  error => Promise.reject(error),
)

Vue.prototype.$http = axiosIns

export default axiosIns
