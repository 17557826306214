import { mdiHumanMaleBoard, mdiVideoCheckOutline, mdiVideoOutline } from '@mdi/js'

export default [
  {
    title: 'Training',
    icon: mdiHumanMaleBoard,
    children: [
      {
        title: 'Available Videos',
        icon: mdiVideoOutline,
        to: { name: 'training-available-videos' },
      },
      {
        title: 'Completed Videos',
        icon: mdiVideoCheckOutline,
        to: { name: 'training-completed-videos' },
      },
    ],
  },
]
